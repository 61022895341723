import { IconRefresh } from '@tabler/icons-react';
import React, { useEffect, useRef } from 'react';
import { PropsWithClassName } from '@Components/helper';
import { iconStroke } from '@Assets/styles/theme/theme';
import { IconBackground } from './ReloadButton.styles';
interface Props extends PropsWithClassName {
  handleReload: () => void;
  canFocusOnTab?: boolean;
  handleFocus?: (isFocused: boolean) => void;
}
const ReloadButton = (props: Props) => {
  const {
    handleReload,
    canFocusOnTab = true,
    handleFocus
  } = props;
  const reloadRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const focusOnTab = (e: KeyboardEvent) => {
      if (e.key !== 'Tab') {
        return;
      }
      e.preventDefault();
      if (canFocusOnTab) {
        // jump to reload
        reloadRef.current?.focus();
        return;
      }
      if (handleFocus) {
        handleFocus(true);
      }
    };
    const reloadKey = (e: KeyboardEvent) => {
      if (e.code !== 'F5') {
        return;
      }
      e.preventDefault();
      handleReload();
    };
    window.addEventListener('keydown', focusOnTab);
    window.addEventListener('keydown', reloadKey);
    return () => {
      window.removeEventListener('keydown', focusOnTab);
      window.removeEventListener('keydown', reloadKey);
    };
  }, [canFocusOnTab, handleFocus, handleReload]);
  return <IconBackground ref={reloadRef} className={props.className} data-testid={props.dataTestId ? props.dataTestId : 'reload-button-root'} onClick={handleReload} data-sentry-element="IconBackground" data-sentry-component="ReloadButton" data-sentry-source-file="ReloadButton.tsx">
      <IconRefresh strokeWidth={iconStroke} data-sentry-element="IconRefresh" data-sentry-source-file="ReloadButton.tsx" />
    </IconBackground>;
};
export default ReloadButton;