import { produce } from 'immer';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { roundTo2 } from '@Root/src/helpers/utils';
import { settings, state, stats, timingState } from '@Root/src/store/typingStoreValues';
import {
  CharType,
  InputKey,
  TypeStatus,
  TypingActions,
  TypingCallbacks,
  TypingSettings,
  TypingState,
  TypingStatsState,
  UserInputChar,
  Word,
} from '@Root/src/store/useTypingStore.types';

import { TypingMode } from '@Api/models/TypingTestResultModel';

import { DEFAULT_LANGUAGE_ISO } from '@Components/helper/language';
import { getTimeFromDateInSeconds } from '@Components/helper/time';
import {
  calcConsistency,
  calculateAccuracy,
  calculateKeystrokesForChar,
  calculateWordWpm,
  getCharsLengthWithoutExtra,
  getCorrectKeystrokesForWord,
  getKeystrokesForWord,
  getKeystrokesForWordWithoutExtra,
  isCorrect,
  isSpaceOrLinebreak,
  mean,
  parseText,
  stdDeviation,
} from '@Components/molecules/WordBox/utils';
import { UseTypingMode } from '@Components/organisms/TypingBox/useTyping.types';

// todo we might use slicing here for timing, stats

const calculateStatsState = (stats: TypingStatsState, words: Word[]): TypingStatsState => {
  stats.spaces = stats.correctWords + stats.wrongWords;
  stats.accuracy = roundTo2(
    calculateAccuracy({
      correctKeystrokes: stats.correctKeystrokes,
      wrongKeystrokes:
        stats.wrongKeystrokes + stats.modificationKeystrokes + stats.extraKeystrokes + stats.missedKeystrokes,
    }) * 100
  );
  const correctSpaces = stats.correctWords;
  const correctKeystrokes = stats.correctKeystrokes + correctSpaces;
  const correctWordKeystrokes = stats.correctWordKeystrokes;
  const totalKeystrokes = stats.correctKeystrokes + stats.wrongKeystrokes + stats.spaces + stats.extraKeystrokes;
  stats.durationInSeconds = Math.round(stats.duration / 1000);
  const perMinute = stats.duration === 0 ? 1 : 60 / stats.durationInSeconds;
  // correct words per minute, use only correctKeystrokes from correctWords, with spaces
  stats.wpm = Math.round((correctWordKeystrokes * perMinute) / 5);
  // correct and wrong with spaces words per minute
  stats.wpmRaw = Math.round((totalKeystrokes * perMinute) / 5);
  // correct chars per minute
  stats.cpm = Math.round(correctKeystrokes * perMinute);
  // all chars per minute
  stats.cpmRaw = Math.round(totalKeystrokes * perMinute);
  // char pace in ms
  stats.pace = Math.round(stats.duration / totalKeystrokes);
  // sum of correct keystrokes and accuracy as second measurement when same wpm
  stats.score = stats.correctKeystrokes * stats.accuracy;
  // keys per minute
  stats.kpm = Math.round(stats.totalCorrectKeys * perMinute);
  stats.kpmRaw = Math.round(stats.totalKeys * perMinute);

  // below values need to iterate the words array.
  // we could improve performance of livestats with omitting live values of consistency

  const keysPerSecondArray: number[] = [];
  let second = 0;
  let keysPerSecond = 0;

  for (const word of words) {
    // break loop when untyped words starts for performance reasons
    if (word.status === TypeStatus.Untyped) {
      break;
    }
    if (word.status === TypeStatus.Typed || word.status === TypeStatus.Active || word.status === TypeStatus.Error) {
      word.keys.map((key: InputKey): void => {
        second += key.usedTime;
        keysPerSecond++;
        if (second >= 1000) {
          keysPerSecondArray.push(keysPerSecond);
          second = second - 1000;
          keysPerSecond = 0;
        }
      });
    }
  }
  // key consistency over time
  const kpmRawArray = keysPerSecondArray.map((k) => Math.round((k / 5) * 60));
  const keysPerSecondDeviation = stdDeviation(kpmRawArray);
  const keysPerSecondAvg = mean(kpmRawArray);
  const consistency = Math.round(calcConsistency(keysPerSecondDeviation / keysPerSecondAvg));
  stats.consistency = isNaN(consistency) ? 0 : consistency;

  return stats;
};

const useTypingStore = create<TypingState & TypingActions>()(
  devtools((set, get): TypingState & TypingActions => ({
    ...state,
    timing: timingState,
    settings: settings,
    stats: stats,
    start: () =>
      set(
        produce((state: TypingState) => {
          state.timing.startTime = new Date();
          state.timing.isRunning = true;
          state.timing.lastStrokeTime = new Date();
        })
      ),
    finish: (callback) =>
      set(
        produce((state: TypingState) => {
          state.timing.endTime = new Date();
          state.timing.isFinished = true;
          state.timing.isRunning = false;
          const startTime = state.timing.startTime?.getTime();
          if (startTime === undefined || startTime === null) {
            throw new Error('startTime is undefined or null');
          }
          const duration = Math.round(state.timing.endTime.getTime() - startTime);
          const settingsDuration = state.settings.durationInSeconds * 1000;
          // duration should not exceed settings.durationInSeconds, endTime is not precise
          state.stats.duration =
            duration > settingsDuration && state.settings.durationInSeconds > -1 ? settingsDuration : duration;
          // get().calculateStatsState(); // this triggers a separate state update
          // better is to extract functionality and combine state update
          state.stats = calculateStatsState(state.stats, state.words);
          if (callback) {
            callback(state.stats, state.words);
          }
        })
      ),
    updatePastSeconds: () =>
      set(
        produce((state: TypingState) => {
          state.timing.pastSeconds = getTimeFromDateInSeconds(state.timing.startTime);
        })
      ),
    updateLastStrokeTime: () =>
      set(
        produce((state: TypingState) => {
          state.timing.lastStrokeTime = new Date();
        })
      ),
    setTypingSettings: (settings: TypingSettings, makeWords?: boolean) => {
      set(
        produce((state: TypingState) => {
          state.settings = settings;
        })
      );
      if (makeWords) {
        get().makeWords();
      }
    },
    setDurationInSeconds: (durationInSeconds: number) =>
      set(
        produce((state: TypingState) => {
          state.settings.durationInSeconds = durationInSeconds;
        })
      ),
    makeWords: () =>
      set((state) => ({
        words: parseText(
          state.settings.text,
          state.settings.languageIso ?? DEFAULT_LANGUAGE_ISO,
          state.settings.shuffle,
          state.settings.charactersLength
        ),
      })),
    setText: (text: string) =>
      set(
        produce((state: TypingState) => {
          state.settings.text = text;
        })
      ),
    setTypingMode: (typingMode: TypingMode) =>
      set(
        produce((state: TypingState) => {
          state.settings.typingMode = typingMode;
        })
      ),
    // do we need this
    setLanguage: (iso: string, label?: string) =>
      set(
        produce((state: TypingState) => {
          state.settings.languageIso = iso;
          if (label !== undefined) {
            state.settings.languageLabel = label;
          }
        })
      ),
    handleDelete: (input: string, previousInputValue: string, keysPerChar: InputKey[]) =>
      set(
        produce((state: TypingState) => {
          const activeWord = state.words.find((word) => word.status === TypeStatus.Active);
          if (!activeWord) {
            return;
          }
          const deletedCharsCount = previousInputValue.length - input.length;
          state.stats.modificationChars += deletedCharsCount;
          activeWord.modificationChars += deletedCharsCount;
          let charIndex = activeWord.chars.findIndex(
            (char) => char.status === TypeStatus.Active && (char.type === CharType.Char || char.type === CharType.Space)
          );
          // last or extra char
          if (charIndex === -1) {
            charIndex = activeWord.chars.length - 2;
          }

          const preDeletedChar = activeWord.chars[charIndex - 1]; // does this work on multiple chars?
          if (preDeletedChar) {
            // add Delete to UserInput of active char
            const userInput = {
              value: '⌫', // ⌫ is backspace
              timestamp: Date.now(),
              usedTime: 0, //getTimeFromDateInMilliseconds(lastStrokeTimeUserInput),
              status: TypeStatus.Undefined,
            };
            preDeletedChar.userInput.push(userInput);
            preDeletedChar.keys?.push(...keysPerChar);
            activeWord.userInput.push(userInput);
          }
          // todo for InputHistory status we need a new flag like corrected=true,
          //  relying on backspace in UserInput is not enough here for ctrl+backspace
          const newIndex = input.length - 1;
          for (let i = charIndex; i >= input.length; i--) {
            const char = activeWord.chars[i];
            const keystrokes = calculateKeystrokesForChar(char.value, state.settings.languageIso);
            // decrease counters to respect corrections and count only once per char
            if (char.status === TypeStatus.Typed) {
              state.stats.correctChars--;
              state.stats.correctKeystrokes -= keystrokes;
            } else if (char.status === TypeStatus.Error) {
              state.stats.wrongChars--;
              state.stats.wrongKeystrokes -= keystrokes;
            }
            state.stats.modificationKeystrokes += keystrokes;
            activeWord.modificationKeystrokes += keystrokes;
            if (activeWord.chars[i].status === TypeStatus.Extra) {
              // remove extra char
              state.stats.extraKeystrokes = state.stats.extraKeystrokes - keystrokes;
              activeWord.chars.splice(i, 1);
              // decrease counter to respect corrections and count only once per char
              state.stats.extraChars--;
            } else {
              // reset all deleted chars to untyped
              activeWord.chars[i].status = TypeStatus.Untyped;
            }
          }
          const activeIndex = newIndex + 1;
          activeWord.chars[activeIndex].status = TypeStatus.Active;
        })
      ),
    handleCharSwitch: (
      input: string,
      status: TypeStatus,
      keysPerChar: InputKey[],
      userInput: UserInputChar,
      typingCallbacks?: TypingCallbacks
    ) =>
      set(
        produce((state: TypingState) => {
          const activeWord = state.words.find((word) => word.status === TypeStatus.Active);
          if (!activeWord) {
            return;
          }
          const activeWordIndex = state.words.findIndex((word) => word.status === TypeStatus.Active);
          const activeCharIndex = activeWord?.chars.findIndex((char) => char.status === TypeStatus.Active);
          const endTime = new Date();
          const nextCharIndex = activeCharIndex + 1;
          const isExtraChar = nextCharIndex >= getCharsLengthWithoutExtra(activeWord);
          if (!isExtraChar) {
            if (status === TypeStatus.Error) {
              state.stats.wrongChars++;
              state.stats.wrongKeystrokes += calculateKeystrokesForChar(
                activeWord.chars[activeCharIndex].value,
                state.settings.languageIso
              );
              typingCallbacks?.onError && typingCallbacks.onError(state.words[activeWordIndex].chars[activeCharIndex]);
            } else if (status === TypeStatus.Typed) {
              state.stats.correctChars++;
              const correctKeystrokes = calculateKeystrokesForChar(
                activeWord.chars[activeCharIndex].value,
                state.settings.languageIso
              );
              // we count the keystrokes of the expected char. Should we count of the actual given char?
              state.stats.correctKeystrokes += correctKeystrokes;
              // we take correctKeystrokes instead of keysPerChar here,
              // because char can be correct but there were possibly unnecessary dead keys typed
              state.stats.totalCorrectKeys += correctKeystrokes;
            }
            // first char? init startTime
            if (activeWord.chars[activeCharIndex].startTime === undefined) {
              activeWord.chars[activeCharIndex].startTime = state.timing.startTime ?? new Date();
            }
            activeWord.chars[activeCharIndex].status = status;
            activeWord.chars[activeCharIndex].endTime = endTime;
            activeWord.chars[activeCharIndex].keys?.push(...keysPerChar);
            activeWord.chars[activeCharIndex].userInput.push(userInput);
            activeWord.chars[nextCharIndex].status = TypeStatus.Active;
            activeWord.chars[nextCharIndex].startTime = new Date();
          } else {
            state.stats.extraChars++;
            state.stats.extraKeystrokes += calculateKeystrokesForChar(
              input[input.length - 1],
              state.settings.languageIso
            );
            activeWord.chars.splice(-1, 0, {
              value: input[input.length - 1],
              type: CharType.Char,
              status: TypeStatus.Extra,
              startTime: state.timing.lastStrokeTime ?? new Date(),
              endTime: endTime,
              userInput: [userInput],
              keys: keysPerChar,
            });
          }
          state.words[activeWordIndex].userInput.push(userInput);
          state.stats.totalKeys += keysPerChar.length;
        })
      ),
    handleWordSwitch: (
      keysPerChar: InputKey[],
      keysPerWord: InputKey[],
      userInput: UserInputChar,
      userInputPerWord: UserInputChar[],
      typingCallbacks?: TypingCallbacks
    ) =>
      set(
        produce((state: TypingState) => {
          const activeWord = state.words.find((word) => word.status === TypeStatus.Active);
          if (!activeWord) {
            return;
          }
          const activeWordIndex = state.words.findIndex((word) => word.status === TypeStatus.Active);
          // space & linebreak end words and are not resetted in charSwitch, so we need to do it here
          const lastCharIndex = activeWord.chars.length - 1;
          if (isSpaceOrLinebreak(activeWord.chars[lastCharIndex])) {
            activeWord.chars[lastCharIndex].status = TypeStatus.Typed;
            activeWord.chars[lastCharIndex].keys?.push(...keysPerChar);
            activeWord.chars[lastCharIndex].userInput.push(userInput);
            state.stats.totalKeys += keysPerChar.length;
          }
          const status = isCorrect(activeWord) ? TypeStatus.Typed : TypeStatus.Error;
          activeWord.status = status;
          // if space was pressed before the end of the word set every remaining char, except space/NL state to error
          if (status === TypeStatus.Error) {
            state.stats.wrongWords++;
            const remainingChars = activeWord.chars.filter(
              (c) =>
                c.status !== TypeStatus.Typed &&
                c.status !== TypeStatus.Error &&
                c.status !== TypeStatus.Extra &&
                c.type === CharType.Char
            );
            for (const char of remainingChars) {
              char.status = TypeStatus.Error; // todo TypeStatus.Missing
              state.stats.missedChars++;
              state.stats.missedKeystrokes += calculateKeystrokesForChar(char.value, state.settings.languageIso);
            }
          } else if (status === TypeStatus.Typed) {
            state.stats.correctWords++;
            state.stats.correctWordChars += activeWord.chars.length;
            state.stats.correctWordKeystrokes += getKeystrokesForWord(activeWord, state.settings.languageIso);
          }
          // if word startTime is not set, it is first word, set it
          if (activeWord.startTime === undefined) {
            activeWord.startTime = activeWord.chars[0].startTime || new Date();
          }
          const wordEndTime = new Date();
          activeWord.endTime = wordEndTime;
          const startTime = activeWord.startTime?.getTime();
          if (startTime === undefined || startTime === null) {
            throw new Error('startTime is undefined or null');
          }
          activeWord.usedTime = wordEndTime.getTime() - startTime;
          activeWord.pace = Math.round(activeWord.usedTime / activeWord.chars.length);
          const wordLength = getKeystrokesForWordWithoutExtra(activeWord, state.settings.languageIso);
          const correctKeystrokes = getCorrectKeystrokesForWord(activeWord, state.settings.languageIso);
          // it is actually wpmRaw because errors are ignored on word level
          activeWord.wpmRaw = calculateWordWpm(wordLength, activeWord.usedTime);
          // wpm with correct chars only
          activeWord.wpm = calculateWordWpm(correctKeystrokes, activeWord.usedTime);
          activeWord.keys = keysPerWord;
          // todo is this necessary, cant we handle this in charSwitch already?,
          //  this would also override value set from charSwitch
          //  do we have cases where charSwitch does not capture everything?
          activeWord.userInput = userInputPerWord;
          // here we go through the next words and find the next one, which can be marked active
          // it's possible that a word is excluded and must be skipped
          let skipCounter = 0;
          let hasMarked = false;
          while (!hasMarked) {
            const nextPossibleWord = state.words[activeWordIndex + 1 + skipCounter];
            if (!nextPossibleWord) {
              hasMarked = true;
              if (
                state.settings.mode === UseTypingMode.TextPractice ||
                state.settings.mode === UseTypingMode.Multiplayer
              ) {
                state.finishedWords = true;
              }
            } else if (nextPossibleWord?.status === TypeStatus.Excluded) {
              skipCounter++;
            } else {
              nextPossibleWord.status = TypeStatus.Active;
              nextPossibleWord.startTime = new Date();
              nextPossibleWord.chars[0].status = TypeStatus.Active;
              hasMarked = true;
            }
          }
          typingCallbacks?.onWordFinish && typingCallbacks.onWordFinish(activeWord);
        })
      ),
    reset: () => {
      set(() => ({
        ...state,
        timing: timingState,
        stats: stats,
      }));
      get().makeWords();
    },
    resetStore: () => {
      set(() => ({
        ...state,
        timing: timingState,
        settings: settings,
        stats: stats,
      }));
    },
  }))
);

export default useTypingStore;
