import { InputHTMLAttributes, useEffect, useMemo, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import useTypingStore from '@Root/src/store/useTypingStore';
import { TypeStatus, TypingCallbacks, UserInputChar } from '@Root/src/store/useTypingStore.types';
import { InputKey, UseTypingMode, Word } from '@Root/src/store/useTypingStore.types';

import { getTimeFromDateInMilliseconds } from '@Components/helper/time';
import { usePrevious } from '@Components/helper/usePrevious';

export const ENTER_CHAR = '⏎';

export interface TypingState {
  inputProps: InputHTMLAttributes<HTMLInputElement>;
}

export const useTypingState = (typingCallbacks?: TypingCallbacks) => {
  const [inputValue, setInputValue] = useState('');
  const previousInputValue = usePrevious(inputValue);
  // we fetch timing values separately to not trigger a rerender
  const lastStrokeTime = useTypingStore((state) => state.timing.lastStrokeTime);
  const startTime = useTypingStore((state) => state.timing.startTime);
  const finishedWords = useTypingStore((state) => state.finishedWords);
  const {
    settingsState,
    words,
    updateLastStrokeTime,
    handleDelete,
    handleCharSwitch,
    handleWordSwitch,
    start,
    finish,
    updatePastSeconds,
  } = useTypingStore(
    useShallow((state) => ({
      settingsState: state.settings,
      words: state.words,
      updateLastStrokeTime: state.updateLastStrokeTime,
      handleDelete: state.handleDelete,
      handleCharSwitch: state.handleCharSwitch,
      handleWordSwitch: state.handleWordSwitch,
      start: state.start,
      finish: state.finish,
      updatePastSeconds: state.updatePastSeconds,
    }))
  );
  const [keysPerChar, setKeysPerChar] = useState<InputKey[]>([]);
  const [keysPerWord, setKeysPerWord] = useState<InputKey[]>([]);
  const [userInputPerWord, setUserInputPerWord] = useState<UserInputChar[]>([]);
  const activeWord = useMemo(() => words.find((word) => word.status === TypeStatus.Active), [words]);
  const activeChar = useMemo(() => activeWord?.chars.find((char) => char.status === TypeStatus.Active), [words]);
  const activeCharIndex = useMemo(
    () => activeWord?.chars.findIndex((char) => char.status === TypeStatus.Active),
    [words]
  );

  const handleWordSwitchState = (
    activeWord: Word,
    keysPerChar: InputKey[],
    keysPerWord: InputKey[],
    userInput: UserInputChar,
    userInputPerWord: UserInputChar[]
  ) => {
    setInputValue((prev) => {
      // clear inputValue and previousInputValue
      const input = prev.split(' ');
      input.shift();
      return input.join(' ');
    });
    handleWordSwitch(keysPerChar, keysPerWord, userInput, userInputPerWord, typingCallbacks);
  };

  const handleDeleteState = (input: string) => {
    handleDelete(input, previousInputValue, keysPerChar);
    setInputValue(input);
  };

  const handleInput = (input: string, composing: boolean) => {
    // Enter does not trigger onChange
    if (!activeWord) {
      return;
    }

    // composing needs first to be finished
    /*if (composing) {
      // todo this triggers rerender, which seems unnecessary
      //  but if we dont set inputValue composing menu does not work properly
      setInputValue(input);
      console.log('sorry composing');
      return;
    }*/

    // it is not allowed to skip the word by pressing only space
    // except for single space words
    // todo unicode (😼) makes trouble since it can be multi-char
    if (input === ' ' && activeWord.chars.length > 1) {
      setInputValue('');
      return;
    }
    // get the active word
    const activeText = activeWord.chars
      .map((w) => w.value)
      .join('')
      .trim();

    const typedChar = input[input.length - 1];

    console.log(
      'activeCharIndex',
      activeCharIndex,
      input.length,
      activeText.length,
      activeText,
      'input:#' + input + '#'
    );
    const isDeleting =
      (activeCharIndex && activeCharIndex >= input.length) ||
      (activeCharIndex === undefined && activeText.length > input.length);

    let status = TypeStatus.Error;
    if (activeChar && typedChar === activeChar.value) {
      status = TypeStatus.Typed;
    }
    const userInput = {
      value: typedChar,
      usedTime: getTimeFromDateInMilliseconds(lastStrokeTime), // todo switch also to start & endtime?
      timestamp: Date.now(),
      status: activeChar ? status : TypeStatus.Extra,
    };
    userInputPerWord.push(userInput);
    if ((typedChar === ' ' || typedChar === ENTER_CHAR) && settingsState.skipWords) {
      // we do not distinct between Space and Enter atm
      handleWordSwitchState(activeWord, [...keysPerChar], [...keysPerWord], userInput, [...userInputPerWord]);
      setKeysPerWord([]); // reset keys
      setKeysPerChar([]);
      setUserInputPerWord([]);
    } else if (isDeleting) {
      //if the user is deleting characters
      handleDeleteState(input);
      setKeysPerChar([]);
    } else {
      handleCharSwitch(input, status, keysPerChar, userInput, typingCallbacks);
      setKeysPerChar([]); // reset keys
      setInputValue(input);
    }
  };
  useEffect(() => {
    if (startTime === null) {
      // reset InputValue when test restarts
      setInputValue('');
    }
    const interval = setInterval(() => {
      if (startTime === null) {
        clearInterval(interval);
        return;
      }
      if (
        (settingsState.mode !== UseTypingMode.TextPractice &&
          settingsState.mode !== UseTypingMode.Multiplayer &&
          startTime.getTime() + settingsState.durationInSeconds * 1000 <= Date.now() - 1000) || // -1000 otherwise timer stops at 0:01
        finishedWords
      ) {
        // todo this is not microsecond accurate: because you can finish words and test will not stop immediately,
        //  but needs next interval to finish which will add some more microseconds
        finish(typingCallbacks?.onFinish);
        clearInterval(interval);
        return;
      }

      updatePastSeconds();
    }, 1000);
    return () => clearInterval(interval);
  }, [startTime, settingsState, finishedWords]);
  // pastSeconds triggers repaint of WordBox, we use starttime + duration for the timer check instead

  return {
    // todo move props one up and remove inputProps ?
    inputProps: {
      value: inputValue,
      onChange: (e) => {
        // todo isComposing is a quite recent addition: https://developer.mozilla.org/en-US/docs/Web/API/InputEvent/isComposing
        //  maybe a problem with older browsers
        handleInput(e.target.value, (e.nativeEvent as InputEvent).isComposing);
      },
      onCompositionEnd: (e) => {
        // onCompositionEnd is triggered only with space not with enter
        // todo when escape or backspace, or in general e.data is empty dont handle input
        handleInput(e.data, false);
      },
      onKeyDown: (e) => {
        // key navigation is disabled
        if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].indexOf(e.code) > -1) {
          e.preventDefault();
        }
        // for uppercase Shift key is sent after char key with onKeyUp,
        // because Shift is released later in typing flow,
        // so we use onKeyDown here
        // start the timer, if not already happened,
        if (startTime === null) {
          start();
        }
        const inputKey: InputKey = {
          value: e.key,
          code: e.code,
          timestamp: Date.now(),
          usedTime: getTimeFromDateInMilliseconds(lastStrokeTime),
        };
        updateLastStrokeTime();
        keysPerChar.push(inputKey);
        keysPerWord.push(inputKey);
        if (e.key === 'Enter') {
          // when composing, Enter should not be handled
          handleInput(inputValue + ENTER_CHAR, e.nativeEvent.isComposing);
        }
      },
    },
  } as TypingState;
};
