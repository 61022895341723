import styled, { css } from 'styled-components';

import Text from '@Components/atoms/Text';
import { ENTER_CHAR } from '@Components/molecules/WordBox/useTypingState';
import { TypingTestFontSize } from '@Components/organisms/TypingBox/TypingBox';
import { TypeStatus } from '@Components/organisms/TypingBox/useTyping.types';

import { UserSelectNone } from '@Assets/styles/theme/mixins';

interface RootProps {
  readonly $highContrast: boolean;
  readonly $fontFace: string;
  readonly $size: TypingTestFontSize;
  readonly $numberOfRows: number;
  readonly $showInvisibleChars: boolean;
}

interface HiddenInputProps {
  readonly $fontSize: TypingTestFontSize;
  readonly $offsetTop: number;
  readonly $offsetLeft: number;
  readonly $inputWidth: number;
}

interface WordBoxTypedProps {
  readonly $wordStatus: TypeStatus;
}

interface WordBoxActiveProps {
  readonly $isFocused: boolean;
  readonly $isDisabled: boolean;
  readonly $direction: 'ltr' | 'rtl';
}

export const LineHeight = 1.625;

export const InactiveBanner = styled.div`
  z-index: 10;
  position: absolute;
  width: 102%;
  height: 106%;
  top: -6px;
  left: -10px;
  animation: blur-in 0.8s linear forwards;

  @keyframes blur-in {
    from {
      backdrop-filter: blur(0px);
    }
    to {
      backdrop-filter: blur(5px);
    }
  }
`;
export const InactiveBannerInner = styled(Text)`
  padding: 0.5rem;
  position: absolute;
  width: 80%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const Root = styled.div`
  position: relative;
  ${() => UserSelectNone()};
  margin-top: ${(props) => props.theme.space.large_32};
  margin-bottom: ${(props) => props.theme.space.xl_64};
  // this is necessary for showInvisibleChars, which renders space as char and word breaks are unclear.
  overflow-wrap: break-word;
  hyphens: manual;
  hyphenate-character: '';

  &.word-box-focused ${InactiveBanner} {
    display: none;
  }

  &:active ${InactiveBanner} {
    display: none;
  }
`;

export const HiddenInput = styled.input<HiddenInputProps>`
  position: absolute;
  cursor: default;
  opacity: 0;
  z-index: -1;
  top: ${(props) => props.$offsetTop}px;
  left: ${(props) => props.$offsetLeft}px;
  min-width: 1rem;
  width: ${(props) => props.$inputWidth}px;
  transform: translateY(-100%);

  font-size: ${(props) => handleFontSize(props.$fontSize)};

  @media (max-width: ${(props) => props.theme.device.tablet}) {
    font-size: ${(props) => handleFontSize(props.$fontSize, true)};
  }
`;

export const WordWrap = styled.div<RootProps>`
  font-family: ${(props) => props.$fontFace};
  white-space: pre-wrap;
  letter-spacing: 1.5px;
  line-height: ${LineHeight};
  overflow: hidden;
  color: ${(props) => props.theme.palette.text.primary}; // todo: highContrast handling
  transition: color ${(props) => props.theme.transition.theme};
  padding-left: 2px;
  margin-left: -2px;
  padding-right: 2px;
  margin-right: -2px;

  font-size: ${(props) => handleFontSize(props.$size)};
  height: ${(props) => `calc(${props.$numberOfRows} * ${handleFontSize(props.$size)} * ${LineHeight})`};

  @media (max-width: ${(props) => props.theme.device.tablet}) {
    font-size: ${(props) => handleFontSize(props.$size, true)};
    height: ${(props) => `calc(${props.$numberOfRows} * ${handleFontSize(props.$size, true)} * ${LineHeight})`};
  }

  ${(props) => {
    // todo can be removed once multiplayer is moved to WordBoxState
    if (props.$showInvisibleChars) {
      return css`
        .linebreak {
          &:before {
            content: '${ENTER_CHAR}';
            font-size: 1rem;
          }
        }

        .space {
          &:before {
            content: '␣\xAD';
            font-size: 1rem;
          }
        }
      `;
    } else {
      return css`
        .space {
          &:before {
            content: ' ';
            font-size: 1.3rem;
          }
        }
      `;
    }
  }}
`;

export const handleFontSize = (size: TypingTestFontSize, isMobile = false) => {
  switch (size) {
    case TypingTestFontSize.VerySmall:
      return isMobile ? '14px' : '16px';
    case TypingTestFontSize.Small:
      return isMobile ? '18px' : '22px';
    case TypingTestFontSize.Medium:
      return isMobile ? '22px' : '30px';
    case TypingTestFontSize.Large:
      return isMobile ? '26px' : '36px';
    case TypingTestFontSize.VeryLarge:
      return isMobile ? '30px' : '42px';
    default:
      return '16px';
  }
};

export const WordBoxActive = styled.span<WordBoxActiveProps>`
  background-color: ${(props) => (props.$isDisabled ? 'transparent' : props.theme.palette.wordBox.active)};
  transition:
    background-color ${(props) => props.theme.transition.theme},
    color ${(props) => props.theme.transition.theme};
  color: ${(props) => props.theme.palette.text.primary};
  transition: background-color ${(props) => props.theme.transition.theme};
`;

export const WordBoxError = styled.span`
  color: ${(props) => props.theme.palette.text.primary};
  background-color: ${(props) => props.theme.palette.wordBox.error};
  transition:
    background-color ${(props) => props.theme.transition.theme},
    color ${(props) => props.theme.transition.theme};
`;

export const WordBoxTyped = styled.span<WordBoxTypedProps>`
  color: ${(props) => props.theme.palette.text.tertiary};
  background-color: ${(props) =>
    props.$wordStatus === TypeStatus.Active ? props.theme.palette.wordBox.typed : 'none'};
  transition:
    background-color ${(props) => props.theme.transition.theme},
    color ${(props) => props.theme.transition.theme};
`;

interface CursorProps {
  readonly left: number;
  readonly top: number;
  readonly height?: number;
  readonly isFocused: boolean;
  readonly isDisabled: boolean;
}

export const Cursor = styled.span<CursorProps>`
  @keyframes pulse {
    0% {
      background-color: ${(props) => props.theme.palette.wordBox.cursor};
    }
    50% {
      background-color: transparent;
    }
    100% {
      background-color: ${(props) => props.theme.palette.wordBox.cursor};
    }
  }
  transition: background-color ${(props) => props.theme.transition.theme};
  animation: ${(props) => (props.isFocused && !props.isDisabled ? 'pulse 1s infinite' : 'unset')};
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  width: 2px;
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.theme.palette.wordBox.cursor};
`;
