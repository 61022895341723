import dynamic from 'next/dynamic';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TypingBoxSettingsModel } from '@Api/localStorage/TypingBoxSettingsModel';
import { CompetitionStatus } from '@Api/models/CompetitionModel';
import { PropsWithClassName } from '@Components/helper';
import { useBreakpoint } from '@Components/helper/useBreakpoint';
import { TypingTestFontSize } from '@Components/organisms/TypingBox/TypingBox';
import { Char, CharType, TypeStatus, TypingProps } from '@Components/organisms/TypingBox/useTyping.types';
import { TextSize } from '@Helpers/types/text';
import { Cursor, handleFontSize, HiddenInput, InactiveBanner, InactiveBannerInner, LineHeight, Root, WordBoxActive, WordBoxError, WordBoxTyped, WordWrap } from './WordBox.styles';
interface Props extends PropsWithClassName {
  typingValues: TypingProps;
  settings: TypingBoxSettingsModel;
  isFocused: boolean;
  handleFocus: (isFocused: boolean) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  languageIso?: string;
  competitionStatus?: CompetitionStatus;
  disabled?: boolean;
}
const WordBox = (props: Props): React.ReactElement => {
  const {
    typingValues,
    competitionStatus,
    isFocused,
    handleFocus,
    inputRef,
    settings,
    languageIso,
    disabled
  } = props;
  const {
    inputProps,
    words,
    charBlocks,
    isRunning
  } = typingValues;
  const {
    t,
    i18n
  } = useTranslation('typing-box');
  const {
    isMobile
  } = useBreakpoint();
  const wordBoxRef = React.createRef<HTMLDivElement>();
  const activeWordBoxRef = React.createRef<HTMLDivElement>();
  const [inputOffsetLeft, setInputOffsetLeft] = useState<number>(0);
  const [inputOffsetTop, setInputOffsetTop] = useState<number>(0);
  const [inputWidth, setInputWidth] = useState<number>(0);
  const [currentLinePos, setCurrentLinePos] = useState<number | null>(null);
  const [currentLine, setCurrentLine] = useState<number>(0);
  const [cursorTop, setCursorTop] = useState<number>(0);
  const [cursorLeft, setCursorLeft] = useState<number>(0);
  const [cursorHeight, setCursorHeight] = useState<number>(0);
  const keepHiddenInputOnActiveWord = useCallback(() => {
    const activeChar = document.querySelector<HTMLSpanElement>('.word-box-active-word');
    const offsetTop = activeChar?.scrollTop ?? 0;
    const offsetHeight = activeChar?.offsetHeight ?? 0;
    const offsetLeft = activeChar?.offsetLeft ?? 0;
    const offsetWidth = activeChar?.offsetWidth ?? 0;
    const WordBoxOffsetWidth = wordBoxRef.current?.offsetWidth ?? 0;
    setInputOffsetTop(offsetTop + offsetHeight);
    if (i18n.dir(languageIso) === 'ltr') {
      setInputWidth(WordBoxOffsetWidth - offsetLeft);
      setInputOffsetLeft(offsetLeft);
      return;
    }
    setInputOffsetLeft(0);
    setInputWidth(offsetLeft + offsetWidth);
  }, [wordBoxRef, languageIso, i18n]);
  const fontSizeMarginMap: Record<TypingTestFontSize, number> = {
    verySmall: isMobile ? 2 : 4,
    small: isMobile ? 3 : 5,
    medium: isMobile ? 3 : 6,
    large: isMobile ? 4 : 7,
    veryLarge: isMobile ? 4 : 8
  };
  const keepActiveWordOnTop = useCallback(() => {
    const char = document.querySelector<HTMLSpanElement>('.word-box-active-char');
    const parentOffset = wordBoxRef?.current?.offsetTop ?? 0;
    if (!isRunning) {
      setCurrentLine(1);
      setCurrentLinePos(null);
      setInputOffsetTop(0);
      wordBoxRef?.current?.scrollTo({
        top: 0
      });
    }
    let scrollToPos = 0;
    if (char && isRunning) {
      const defaultMarginTop = fontSizeMarginMap[settings.fontSize]; // used to avoid words shift on first calculation
      const textViewPos = char.offsetTop - parentOffset - defaultMarginTop;
      let nextLine = currentLine;
      // increment line if pos has changed
      if (currentLinePos !== 0 && textViewPos !== currentLinePos) {
        nextLine = currentLine + 1;
      }
      setCurrentLinePos(textViewPos);
      scrollToPos = textViewPos;
      // if line is greater than rows - 1 always scroll but stay in next to last
      const skipScroll = nextLine <= settings.rows - 1;
      if (!skipScroll && currentLine !== nextLine || settings.rows <= 2) {
        if (settings.rows > 2) {
          const fontSize = parseInt(handleFontSize(settings.fontSize, isMobile));
          // -2 because next to last
          scrollToPos = textViewPos - fontSize * LineHeight * (settings.rows - 2);
        }
        wordBoxRef?.current?.scrollTo({
          top: scrollToPos
        });
      }
      setCurrentLine(nextLine);
    }
    // we need subtract the scrolling
    const activeWordBoxOffsetTop = activeWordBoxRef?.current?.offsetTop ?? 0;
    const wordBoxScrollTop = wordBoxRef?.current?.scrollTop ?? 0;
    setCursorTop(activeWordBoxOffsetTop - wordBoxScrollTop);
    let offsetLeft = activeWordBoxRef?.current?.offsetLeft ?? 0;
    if (i18n.dir(languageIso) === 'rtl') {
      offsetLeft = offsetLeft + (activeWordBoxRef?.current?.offsetWidth ?? 0);
    }
    setCursorLeft(offsetLeft);
    setCursorHeight(activeWordBoxRef?.current?.offsetHeight ?? 30);
  }, [wordBoxRef, activeWordBoxRef, settings, isRunning]);
  useEffect(() => {
    keepActiveWordOnTop();
    keepHiddenInputOnActiveWord();
  }, [inputProps.value, words, keepActiveWordOnTop, keepHiddenInputOnActiveWord]);
  const renderWords = (): ReactNode => {
    if (competitionStatus === CompetitionStatus.Completed) {
      return t('competition.message.completed');
    }
    if (competitionStatus === CompetitionStatus.Pending) {
      return t('competition.message.pending');
    }
    const makeText = (chars: Char[]) => chars.map((char, key) => {
      return char.type === CharType.Space ? <span className={'space'} key={key}></span> : char.type === CharType.Linebreak ? <span className={'linebreak'} key={key}>
            {char.value}
          </span> : char.value;
    });
    return charBlocks.map(({
      chars,
      status,
      wordStatus
    }, index) => {
      const activeWordClassName = wordStatus === TypeStatus.Active ? 'word-box-active-word' : '';
      // todo group multiple spaces into one active word
      // todo cursor should still bo only on first space
      // todo on type cursor should jump to next char, not space
      // we could group spaces
      const text = makeText(chars);
      switch (status) {
        case TypeStatus.Excluded:
          return <span key={index}>{text}</span>;
        case TypeStatus.Typed:
          return <WordBoxTyped $wordStatus={wordStatus ?? TypeStatus.Undefined} className={activeWordClassName} key={index} data-testid="word-box-typed">
              {text}
            </WordBoxTyped>;
        case TypeStatus.Error:
        case TypeStatus.Extra:
          return <WordBoxError className={activeWordClassName} key={index} data-testid="word-box-error">
              {text}
            </WordBoxError>;
        case TypeStatus.Active:
          return <WordBoxActive ref={activeWordBoxRef} key={index} className={`word-box-active-char ${activeWordClassName}`} $isFocused={isFocused} $direction={i18n.dir(languageIso)} $isDisabled={disabled ?? false} data-testid="word-box-active">
              {text}
            </WordBoxActive>;
        default:
          return <span className={activeWordClassName} key={index}>
              {text}
            </span>;
      }
    });
  };
  return <Root data-testid={'WordBox-root'} onClick={() => handleFocus(true)} dir={i18n.dir(languageIso)} className={isFocused ? `${props.className} word-box-focused` : props.className} data-sentry-element="Root" data-sentry-component="WordBox" data-sentry-source-file="WordBox.tsx">
      <WordWrap ref={wordBoxRef} $size={settings.fontSize} $highContrast={settings.useHighContrast} $numberOfRows={settings.rows} $fontFace={settings.fontFace} $showInvisibleChars={settings.showInvisibleChars} data-sentry-element="WordWrap" data-sentry-source-file="WordBox.tsx">
        <Cursor isFocused={isFocused} isDisabled={disabled ?? false} top={cursorTop} left={cursorLeft} height={cursorHeight} data-testid="word-box-cursor" data-sentry-element="Cursor" data-sentry-source-file="WordBox.tsx" />
        {renderWords()}
      </WordWrap>
      <HiddenInput ref={inputRef} onBlur={() => handleFocus(false)} $offsetLeft={inputOffsetLeft} $offsetTop={inputOffsetTop} $inputWidth={inputWidth} $fontSize={settings.fontSize} {...inputProps} disabled={disabled} data-testid={`Typing-box-input`} data-sentry-element="HiddenInput" data-sentry-source-file="WordBox.tsx" />
      <InactiveBanner data-sentry-element="InactiveBanner" data-sentry-source-file="WordBox.tsx">
        <InactiveBannerInner size={TextSize.Large} dataTestId="InactiveBanner" data-sentry-element="InactiveBannerInner" data-sentry-source-file="WordBox.tsx">
          {t('click_to_focus')}
        </InactiveBannerInner>
      </InactiveBanner>
    </Root>;
};
export default dynamic(() => Promise.resolve(WordBox), {
  ssr: false
});